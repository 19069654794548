@import "~bulma/sass/utilities/initial-variables";

$break-small-laptop: 1215px;
$break-medium: 1023px;
$break-small: 768px;
$primary: #baf84e;
$primary-invert: black;
$dark: #171717;
$grey-dark: black;
$navbar-burger-color: white;

@import "~bulma";
@import "~bulma-pageloader";

html, body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  height: 100%;
  background: #ECF0F3;
}

.home-section {
  height: 100vh;
  display: flex;
  align-items: center;
  @media screen and (max-width: $break-small) {
    height: auto;
    padding: 3em 1em;
  }
}

.pageloader {
  background: black !important;
}

.navbar{
  @media screen and (min-width: $break-medium) {
    padding-top: 1em;
    padding-bottom: 1em;
    .image{
      max-height : 3.2rem
    }
  }

  @media screen and (max-width: $break-small) {
    left: 0;
    position: fixed;
    right: 0;
    z-index: 200;
    top: 0;
  }
  .navbar-burger:hover{
    color: rgba(255, 255, 255, 1);
  }
}

.dashboard {
  padding: 3em 1em;
  @media screen and (max-width: $break-small) {
    height: auto;
    padding: 4.5rem 0.5em 1rem;
  }
}

.social-logos{
  display: flex;
  justify-content: center;
  .social-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 7px;
    height: 40px;
    width: 40px;
    margin-top: 1em;
    margin-bottom: 2em;
    img {
      max-width: 20px;
    }
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
}

.admin-menu-container {
  @media screen and (max-width: $break-small) {
  position: fixed;
    background: #FBFBFE;
    z-index: 100;
    top: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transition: all 250ms cubic-bezier(0.165, 0.84, 0.44, 1);
    padding-top: 4.5rem;
    &.is-active{
      transform: translateX(4px);
      -webkit-transform: translateX(4px);
    }
  }
}

.arrow {
  width: 12px;
  height: 12px;
  position: relative;
  display: inline-block;
  
  &:before, 
  &:after {
    content: '';
    border-color: transparent;
    border-style: solid;
    position: absolute;

  }
}

.arrow-left{
  &:before {
    border: none;
    background-color: #171717;
    height: 30%;
    width: 50%;
    top: 35%;
    left: 50%;
  }
  &:after {
    left: -50%;
    top: 0;
    border-width: 6px;
    border-right-color: #171717;
  }
}

.arrow-right{
  &:before {
    border: none;
    background-color: #171717;
    height: 30%;
    width: 50%;
    top: 35%;
    left: 0;
  }
  &:after {
    left: 50%;
    top: 0;
    border-width: 6px;
    border-left-color: #171717;
  }
}

.class-day-panel {
  .panel-block {
    display: block;
  }
}

.skeleton-box {
  display: inline-block;
  height: 4em;
  position: relative;
  overflow: hidden;
  width: 100%;
  background-color: #DDDBDD;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}